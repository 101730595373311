import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'HomeView',
		component: HomeView
	},
	{
		path: '/signin',
		name: 'SignIn',
		component: () => import('@/views/SignIn/SignIn.vue'),
	},
	{
		path: '/membercenter',
		name: 'MemberCenter',
		component: () => import('@/views/MemberCenter/MemberCenter.vue'),
	},
	{
		path: '/memberactivation',
		name: 'MemberActivation',
		component: () => import('@/views/MemberActivation/MemberActivation.vue'),
	},
	{
		path: '/aboutus',
		name: 'AboutUs',
		component: () => import('@/views/AboutUs/AboutUs.vue'),
	},
	{
		path: '/enterpriseservices',
		name: 'EnterpriseServices',
		component: () => import('@/views/EnterpriseServices/EnterpriseServices.vue'),
	},
	{
		path: '/formofbid',
		name: 'FormOfBid',
		component: () => import('@/views/FormOfBid/FormOfBid.vue'),
	},
	{
		path: '/registerservice',
		name: 'RegisterService',
		component: () => import('@/views/SignIn/serviceAndPrivacy/RegisterService.vue'),
	},
	{
		path: '/privacyterms',
		name: 'PrivacyTerms',
		component: () => import('@/views/SignIn/serviceAndPrivacy/PrivacyTerms.vue'),
	},
	{
		path: '/disclaimerstatement',
		name: 'DisclaimerStatement',
		component: () => import('@/components/DisclaimerStatement/DisclaimerStatement.vue'),
	},
	{
		path: '/detailsearch',
		name: 'DetailSearch',
		component: () => import('@/components/DetailSearch/DetailSearch.vue'),
		meta: {
			//此页面需要缓存
			keepAlive: true,
		}
	},
	{
		path: '/detailspage',
		name: 'DetailsPage',
		component: () => import('@/components/DetailsPage/DetailsPage.vue'),
	},
	{
		path: '/tenderdetails',
		name: 'TenderDetails',
		component: () => import('@/components/TenderDetails/TenderDetails.vue'),
	},
	{
		path: '/mysubscriptions',
		name: 'MySubscriptions',
		component: () => import('@/views/MySubscriptions/MySubscriptions.vue'),
	},
	{
		path: '/commonproblem',
		name: 'CommonProblem',
		component: () => import('@/components/CommonProblem/CommonProblem.vue'),
	},
	{
		path: '/pagelost',
		name: 'PageLost',
		component: () => import('@/components/PageLost/PageLost.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router

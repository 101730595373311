// import Vue from 'vue'
// 引入axios
import axios from 'axios';
import router from '../router'
import DifferentEnvironment from '@/DifferentEnvironment.vue'
// 请求延时（毫秒数，如果请求话费超过了'timeout'的时间，请求将被中断）
// Vue.prototype.Environment
axios.defaults.timeout = 100000;
axios.defaults.baseURL = DifferentEnvironment.target

// 搜索请求
export const postAllSearchData = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.post('/v1/api/search', params);
};
// 账号密码登录
export const postRequestLogin = params => {
	return axios.post('/v1/auth/login', params);
};
// 获取信息详情
export const getDetail = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.get('/v1/api/detail', {
		params: params,
	});
};
// 获取订阅信息
export const getSubscirber = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.get('/v1/api/subscirber', {
		params: params,
	});
};
// 设置订阅信息
export const postSubscirber = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.post('/v1/api/subscirber', params);
};
// 获取统计数据
export const getStatistics = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.get('/v1/api/statistics/today?summary=1', {
		params: params,
	});
};
// 获取用户信息
export const getMember = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.get('/v1/api/member', {
		params: params,
	});
};
// 修改个人信息
export const postMember = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.post('/v1/api/member', params);
};
// 微信登录
export const getWechat = params => {
	return axios.get('/v1/auth/wechat', {
		params: params,
	});
};
// 获取浏览记录
export const getRecords = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.get('/v1/api/records', {
		params: params,
	});
};
// 获取收藏列表
export const getCollection = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.get('/v1/api/collection', {
		params: params,
	});
};
// 收藏/取消收藏
export const postCollection = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.post('/v1/api/collection', params);
};
// 微信支付
export const postOrder = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.post('/v1/api/pay/order', params);
};
// 生成微信签名
export const getSignature = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem("access_token")
	return axios.get('/v1/api/wechat/signature', {
		params: params,
	});
};
// 账号密码登录
export const postLogIn = params => {
	return axios.post('/v1/auth/login', params);
};
// 注册账号
export const postRegister = params => {

	return axios.post('/v1/auth/register', params);
};
// 修改密码
export const postResetPasswd = params => {
	return axios.post('/v1/auth/reset_passwd', params);
};
// 获取验证码
export const postGetCode = params => {
	return axios.post('/v1/auth/get_code', params);
};
// 获取登录二维码与uid
export const getQrcode = params => {
	return axios.get('/v1/api/wechat/qrcode', {
		params: params,
	});
};
//扫码获取登录状态
export const getLogin = params => {
	return axios.get('/v1/api/wechat/login', {
		params: params,
	});
};
// 使用refresh_token
export const postRefresh = params => {
	axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem('refresh_token')
	return axios.post('/v1/auth/refresh', params);
};


// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    // 对响应数据做处理，如果需要的话
    return response;
  },
  error => {
    // 捕获错误并进行处理
    if (error.response && error.response.status === 401) {
      // 处理 401 异常，例如跳转到登录页
      console.log('捕获到 401 异常');
      // 执行你的处理逻辑，例如跳转到登录页
      router.push('/signin');
    } else {
      // 其他异常处理逻辑
      console.error('全局异常:', error);
    }
    return Promise.reject(error);
  }
);
<!-- 友情链接模块 -->
<template>
	<div class="bottomof-page" :style="{'--theme-color': themeColor}">
		<div class="cooperation">
			<el-divider>
				<div class="links-top">友情链接</div>
			</el-divider>
			<el-row type="flex" class="links" justify="space-between">
				<div class="diamond" v-for="(item,index) in friendshipPictures" :key="index"><a :href="item.website"
						target="_blank"><img :src="item.picture" alt=""></a></div>
			</el-row>
		</div>
		<div class="bottom-navigation">
			<el-row type="flex" justify="center">
				<span class="nav-option" @click="aboutUs">
					关于我们
				</span>
				<el-divider direction="vertical"></el-divider>
				<span class="nav-option" @click="aboutUs(1)">联系方式

				</span>
				<el-divider direction="vertical"></el-divider>
				<!-- <span class="nav-option">合作加盟</span>
				<el-divider direction="vertical"></el-divider> -->
				<span class="nav-option">
					<router-link to="/disclaimerstatement">
						免责声明
					</router-link>
				</span>
				<el-divider direction="vertical"></el-divider>
				<span class="nav-option">
					<router-link to="/privacyterms">
						隐私政策
					</router-link>
				</span>
				<el-divider direction="vertical"></el-divider>
				<span class="nav-option">
					<router-link to="/registerservice">
						注册协议
					</router-link>
				</span>
				<el-divider direction="vertical"></el-divider>
				<span class="nav-option" @click="onCommonProblem">常见问题</span>
			</el-row>
		</div>
		<div class="bottom-description">
			<el-row type="flex" justify="center" v-if="state == 2">
				主办单位：吉林能者科技有限公司 服务电话：13224423043 <a target="_blank"
					href="https://beian.miit.gov.cn/">吉ICP备19006495号-1</a>吉公网安备
				22021102000201号
			</el-row>
			<el-row type="flex" justify="center" v-if="state == 1">
				主办单位：北京智慧融合科技有限公司 服务电话：13224423043 <a target="_blank"
					href="https://beian.miit.gov.cn/">京ICP备2022034334号-2</a>
			</el-row>
			<el-row type="flex" justify="center" class="english">
				Operated by Beijing Zhihui Ronghe Limited Company.
			</el-row>
		</div>
		<div class="the-link">
			<el-row type="flex" justify="center">
				<div v-for="(item,index) in theLowestScore" :key="index">
					<a target="_blank" :href="item.url">
						<img :src="item.img">
					</a>
				</div>
			</el-row>
		</div>
	</div>
</template>

<script>
	import DifferentEnvironment from '@/DifferentEnvironment.vue'
	export default {
		name: 'BottomofPage',
		components: {

		},
		data() {
			return {
				state: DifferentEnvironment.state,
				themeColor: this.$root.global.themeColor,
				theLowestScore: [{
						url: 'https://www.12377.cn',
						img: 'http://nzzbw.com/up/image/ico/bot_1.gif',
					},
					{
						url: 'http://cyberpolice.mps.gov.cn/wfjb',
						img: 'http://nzzbw.com/up/image/ico/bot_2.gif',
					},
					{
						img: 'http://nzzbw.com/up/image/ico/bot_3.png',
					},
				],
				friendshipPictures: [{
						website: 'http://www.hongyedianqi.com',
						picture: 'http://nzzbw.com/up/image/gg/01.png',
					},
					{
						website: 'http://yifan001.goepe.com',
						picture: 'http://nzzbw.com/up/image/gg/2.png',
					},
					{
						website: 'http://www.wmh999.cn',
						picture: 'http://nzzbw.com/up/image/gg/03.png',
					},
					{
						website: 'http://enlonhubery.cn.tonbao.com',
						picture: 'http://nzzbw.com/up/image/gg/04.png',
					},
					{
						website: 'http://www.zzdq.cn',
						picture: 'http://nzzbw.com/up/image/gg/05.png',
					},
					{
						website: 'http://www.chko.cn',
						picture: 'http://nzzbw.com/up/image/gg/06.png',
					},
					{
						website: 'http://www.reneshine.com',
						picture: 'http://nzzbw.com/up/image/gg/07.png',
					},
				],
			}
		},
		mounted() {
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
		},
		methods: {
			aboutUs(est) {
				if (location.pathname != '/aboutus') {
					this.$router.push({
						name: 'AboutUs',
						params: {
							est: est
						}
					})
				} else {
					if (est != 1) {
						var timerss = setInterval(function() {
							let osTop = document.documentElement.scrollTop || document.body.scrollTop
							let ispeed = Math.floor(-osTop / 5)
							document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
							this.isTop = true
							if (osTop === 0) {
								clearInterval(timerss)
							}
						}, 30)
					}
				}
			},
			onCommonProblem() {
				this.$router.push({
					name: 'CommonProblem',
				})
			},
		}
	}
</script>

<style>
	.bottomof-page .cooperation {
		padding: 0 10%;
		padding-top: 8px;
		border-radius: 5px;
		margin-top: 20px;
		text-align: center;
		background-color: #fff;
		text-align: center;
	}

	.bottomof-page .cooperation .links-top {
		padding: 5px 20px;
		color: #fff;
		background-color: #fdfdfd;
		border: 2px solid #DCDFE6;
		color: #000;
	}

	.bottomof-page .links {
		padding: 0 30px;
		margin-top: 40px;
		padding-bottom: 30px;
	}

	.bottomof-page .links img {
		width: 100px;
		height: 100%;
		border: 2px solid #f5f5f5;
	}

	.bottomof-page .diamond {
		width: 100px;
	}

	.bottomof-page .bottom-navigation {
		font-size: 14px;
		padding: 0 10%;
		background-color: #fff;
	}

	.bottomof-page .bottom-navigation .nav-option {
		cursor: pointer;
	}

	.bottomof-page .bottom-navigation .nav-option a {
		text-decoration: none;
		color: #000;
	}

	.bottomof-page .bottom-navigation .nav-option a:hover {
		color: var(--theme-color);
	}

	.bottomof-page .bottom-navigation .nav-option:hover {
		color: var(--theme-color);
	}

	.bottomof-page .home-body .the-lowest-score {
		padding: 20px 0;
		background-color: #fff;
	}

	.bottomof-page .home-body .the-lowest-score a {
		margin: 0 20px;
	}

	.bottomof-page .bottom-navigation .nav-option {
		cursor: pointer;
	}

	.bottomof-page .bottom-navigation .nav-option a {
		text-decoration: none;
		color: #000;
	}

	.bottomof-page .bottom-navigation .nav-option a:hover {
		color: var(--theme-color);
	}

	.bottomof-page .bottom-navigation .nav-option:hover {
		color: var(--theme-color);
	}

	.bottomof-page .el-divider--vertical {
		margin: 0 6% !important;
	}

	.bottomof-page .the-link {
		padding: 20px 0;
		background-color: #fff;
	}

	.bottomof-page .the-link a {
		margin: 0 20px;
	}

	.bottomof-page .bottom-description {
		font-size: 14px;
		padding: 0 10%;
		padding-top: 20px;
		background-color: #fff;
	}

	.bottomof-page .bottom-description a {
		margin: 0 20px;
		font-weight: bold;
	}

	.bottomof-page .bottom-description .english {
		margin-top: 5px;
	}

	.bottomof-page .el-divider__text {
		padding: 0 !important;
	}
</style>

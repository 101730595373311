<!-- 首页 -->
<template>
	<div class="home-body" :style="{'--theme-color': themeColor}">
		<div v-if="fullscreenLoading == false">
			<div class="home">
				<TopNav :class="{top_nav:0 != scrollTop}" :loginornot="loginornot"></TopNav>
				<div class="home-title" v-if="state == 1">找 商 机 · 上 标 标 通</div>
				<div class="home-title" v-if="state == 2">找 商 机 · 上 能 者 招 标</div>
				<div class="search">
					<el-input placeholder="请输入您要查询的招标信息" v-model="searchContent" class="input-with-select">
						<el-select v-model="mode" slot="prepend" class="input-hover">
							<el-option label="标题" value="20" :style="{ '--theme-color': themeColor }"></el-option>
							<el-option label="全文" value="10" :style="{ '--theme-color': themeColor }"></el-option>
						</el-select>
						<el-button @click="onSearch" v-if="searchContent != ''" slot="append" class="searchButton"
							icon="el-icon-search">
						</el-button>
						<el-button v-else slot="append" icon="el-icon-search"></el-button>
					</el-input>
				</div>
				<el-row type="flex">
					<div class="hotSearch">热门搜索 ： <span v-for="(item,index) in hotSearch" :key="index"
							@click="onKeyWord(item)" class="search-term">{{item}}</span></div>
				</el-row>
			</div>
			<div class="user-left">
				<div class="official-account">
					<el-popover placement="left" width="200" trigger="hover">
						<img class="concernqrcode" src="@/assets/biaobiaotongqrcode.jpg" v-if="state == 1">
						<img class="concernqrcode" src="@/assets/qrcode.jpg" v-if="state == 2">
						<div class="concern-no">扫码关注公众号</div>
						<div slot="reference">
							<img src="@/assets/scanningcode.png">
							<div>公众号</div>
						</div>
					</el-popover>
				</div>
				<div class="official-account">
					<el-popover placement="left" width="200" trigger="hover">
						<div>联系电话：13224423043</div>
						<div slot="reference">
							<img src="@/assets/realtimeinfo.png">
							<div>立即咨询</div>
						</div>
					</el-popover>
				</div>
				<div class="back-to-top" @click="onBackToTop" v-if="scrollTop >= 300">
					<img src="@/assets/backtotop.png">
					<div>返回顶部</div>
				</div>
			</div>
			<div class="platformData">
				<el-row type="flex">
					<el-col>
						<div class="hand">
							<div class="platformData-top">招投标信息</div>
							<div class="platformData-date">
								<DigitalAnimation :value="biddingInformation" class="text-color-blue nums">
								</DigitalAnimation>
							</div>
						</div>
					</el-col>
					<el-col>
						<div class="hand">
							<div class="platformData-top">近30日推送</div>
							<div class="platformData-date">
								<DigitalAnimation :value="cumulativePush" class="text-color-blue nums">
								</DigitalAnimation>
							</div>
						</div>
					</el-col>
					<el-col>
						<div class="hand">
							<div class="platformData-top">近30天更新</div>
							<div class="platformData-date">
								<DigitalAnimation :value="cumulativeUpdate" class="text-color-blue nums">
								</DigitalAnimation>
							</div>
						</div>
					</el-col>
					<el-col>
						<div class="hand">
							<div class="platformData-top">今日更新</div>
							<div class="platformData-date">
								<DigitalAnimation :value="updatedToday" class="text-color-blue nums"></DigitalAnimation>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="bidding-screening">
				<el-row>
					<el-col class="bidding-screening-left">
						<div class="classification">
							<el-row type="flex" justify="space-between">
								<el-row type="flex" class="bidding-screening-top">按类型：
									<div class="classification-bytype" @click="onByType(index,item)"
										:class="{active:index == typedisplay}" v-for="(item,index) in byType"
										:key="index">
										{{item}}
									</div>
								</el-row>
								<div class="seemore" @click="onSeeMore">查看更多<span class="el-icon-arrow-right"></span>
								</div>
							</el-row>
							<el-row type="flex" justify="space-between" class="top-margin">
								<el-row type="flex" class="bidding-screening-top">按地区：
									<div class="classification-bytype" @click="onByRegion(index,item)"
										:class="{active:index == byRegion_id}" v-for="(item,index) in byRegion"
										:key="index">
										{{item.name}}
									</div>
								</el-row>
								<div class="seemore" @click="onSeeMore">查看更多<span class="el-icon-arrow-right"></span>
								</div>
							</el-row>
						</div>
						<div class="lump">
							<div class="latest-content" @click="onProject">
								<el-row type="flex" justify="space-between">
									<div>最新项目</div>
									<div>
										<el-row type="flex">
											<div class="seemore">查看更多</div>
											<div class="el-icon-d-arrow-right"></div>
										</el-row>
									</div>
								</el-row>
							</div>
							<div class="list-top" v-loading="loading != 0">
								<div class="list" @click="onList(item,index)" v-for="(item,index) in projectList "
									:key="index">
									<el-row type="flex" justify="space-between">
										<div class="list-type" v-if="item.bid_type == 133">招标公告</div>
										<div class="list-type" v-if="item.bid_type == 134">中标公告</div>
										<div class="list-type" v-if="item.bid_type == 135">招标变更</div>
										<div class="list-type" v-if="item.bid_type == 155">拟建项目</div>
										<div class="list-type" v-if="item.bid_type == 156">招标预告</div>
										<div class="list-type" v-if="item.bid_type == 178">资格预审</div>
										<div class="list-type" v-if="item.bid_type == 179">中标候选</div>
										<div class="list-region">{{item.region}}</div>
										<div class="list-title" v-html="item.title"></div>
										<div class="list-time">{{item.date}}</div>
									</el-row>
								</div>
								<div v-if="projectList.length == 0" @click="onSignin" v-loading="loading != 0">
									<div class="not-logged-in"><img src="@/assets/notloggedin.png"></div>
									<div class="please-login" v-if="loginornot == 2">暂无信息</div>
									<div class="please-login" v-else>请先登录</div>
								</div>
							</div>
						</div>
						<div class="lump">
							<div class="latest-content" @click="onProject">
								<el-row type="flex" justify="space-between">
									<div>最新公告</div>
									<div>
										<el-row type="flex">
											<div class="seemore">查看更多</div>
											<div class="el-icon-d-arrow-right"></div>
										</el-row>
									</div>
								</el-row>
							</div>
							<div class="list-top">
								<div class="list" @click="onList(item)" v-for="(item,index) in bulletinList "
									:key="index">
									<el-row type="flex" justify="space-between">
										<div class="list-type" v-if="item.bid_type == 133">招标公告</div>
										<div class="list-type" v-if="item.bid_type == 134">中标公告</div>
										<div class="list-type" v-if="item.bid_type == 135">招标变更</div>
										<div class="list-type" v-if="item.bid_type == 155">拟建项目</div>
										<div class="list-type" v-if="item.bid_type == 156">招标预告</div>
										<div class="list-type" v-if="item.bid_type == 178">资格预审</div>
										<div class="list-type" v-if="item.bid_type == 179">中标候选</div>
										<div class="list-region">{{item.region}}</div>
										<div class="list-title">{{item.title}}</div>
										<div class="list-time">{{item.date}}</div>
									</el-row>
								</div>
								<div v-if="bulletinList.length == 0" @click="onSignin" v-loading="notice != 0">
									<div class="not-logged-in"><img src="@/assets/notloggedin.png"></div>
									<div class="please-login" v-if="loginornot == 2">暂无信息</div>
									<div class="please-login" v-else>请先登录</div>
								</div>
							</div>
						</div>
						<div class="latest-content">
							<el-row type="flex" justify="space-between">
								<div>热门关键词</div>
								<div @click="onIncrease">
									<el-row type="flex">
										<div class="seemore">查看更多</div>
										<div class="el-icon-d-arrow-right"></div>
									</el-row>
								</div>
							</el-row>
						</div>
						<div class="keyword-hot">
							<div class="keyword" v-for="(item,index) in popularKeywords" :key="index"
								@click="onKeyWord(item)">{{item}}
							</div>
							<div class="stow" v-if="popularKeywords.length > 11" @click="onStow">收起</div>
						</div>
					</el-col>
					<el-col class="bidding-screening-right">
						<div class="subscribe" @click="onSignin" v-if="loginornot === 1">
							<div class="tips-top">登录后可享受</div>
							<div class="mobilePhone">
								<img src="@/assets/mobilephone.png">
							</div>
							<div class="tips-in">每天一条招标信息</div>
							<div class="Tips-bottom" v-if="state == 2">
								关注能者公众号随时随地查看商机
							</div>
							<div class="Tips-bottom" v-if="state == 1">
								关注表表通公众号随时随地查看商机
							</div>
							<div class="sign-in">立即登录</div>
						</div>
						<div class="upper" v-if="loginornot === 1"></div>
						<div class="subscribe" @click="onSubscribe">
							<div class="bidding">招标订阅</div>
							<div class="bidding-tips">专属信息 + 微信推送 + 邮箱推送</div>
						</div>
						<div class="navigation">
							<div class="popular-navigation">热门招标导航</div>
							<div class="popular-navigation-list">
								<div class="nav-list" @click="onBiddingNavigation(item)"
									v-for="(item,index) in biddingNavigation " :key="index">{{item.name}}</div>
							</div>
						</div>
						<div class="navigation">
							<div class="popular-navigation">企业服务</div>
							<div class="popular-navigation-list">
								<div class="nav-list" @click="onService(index)" v-for="(item,index) in  service"
									:key="index">{{item}}</div>
							</div>
						</div>
						<!-- <div class="navigation">
							<div class="popular-navigation">标书范本</div>
							<div class="popular-navigation-list">
								<div class="nav-list" @click="onFormofbid(index)" v-for="(item,index) in  Formofbid"
									:key="index">{{item}}</div>
							</div>
						</div> -->
						<div class="navigation">
							<div class="popular-navigation">招标机构</div>
							<div class="popular-navigation-list">
								<div class="nav-list" v-for="(item,index) in  biddingAgency" :key="index"><a
										:href="item.url" target="_blank">{{item.name}}</a>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<BottomofPage></BottomofPage>
		</div>
		<div v-loading.fullscreen.lock="fullscreenLoading">

		</div>
	</div>
</template>

<script>
	import {
		postAllSearchData,
		getStatistics
	} from '@/api/api.js'
	import DigitalAnimation from '@/components/DigitalAnimation/DigitalAnimation.vue'
	import BottomofPage from '@/components/BottomofPage/BottomofPage.vue'
	import TopNav from '@/components/TopNav/TopNav.vue'
	import DifferentEnvironment from '@/DifferentEnvironment.vue'
	export default {
		name: 'HomeView',
		components: {
			TopNav,
			BottomofPage,
			DigitalAnimation
		},
		data() {
			return {
				state: DifferentEnvironment.state,
				loading: 0,
				notice: 0,
				fullscreenLoading: true,
				homeKeyWord: '',
				homeRegion: [],
				themeColor: this.$root.global.themeColor,
				loginStatus: 1,
				biddingAgency: [{
						name: '中国大唐',
						url: 'http://www.china-cdt.com',
					},
					{
						name: '中国电建',
						url: 'https://www.powerchina.cn',
					},
					{
						name: '中国国电',
						url: 'http://www.600795.com.cn',
					},
					{
						name: '国家电投',
						url: 'http://www.spic.com.cn',
					},
					{
						name: '国家电网',
						url: 'http://www.sgcc.com.cn',
					},
					{
						name: '中国华能',
						url: 'http://www.chng.com.cn',
					},
					{
						name: '华润电力',
						url: 'https://www.cr-power.com',
					},
					{
						name: '南方电网',
						url: 'http://www.csg.cn',
					},
					{
						name: '中国能建',
						url: 'http://www.ceec.net.cn',
					},
					{
						name: '神华集团',
						url: 'http://www.ceic.com',
					},
					{
						name: '中广核',
						url: 'http://www.cgnpc.com.cn',
					},
					{
						name: '中国铁塔',
						url: 'https://www.china-tower.com/index',
					},
					{
						name: '中国华能',
						url: 'http://www.chd.com.cn',
					},
					{
						name: '政采云',
						url: 'https://www.zcygov.cn/',
					},
					{
						name: '政府采购',
						url: 'http://www.zycg.gov.cn',
					},
				],
				bottomNav: [{
						name: '关于我们',
						id: 0,
					},
					{
						name: '联系方式',
						id: 1,
					},
					{
						name: '合作加盟',
						id: 2,
					},
					{
						name: '免责声明',
						id: 3,
					},
					{
						name: '隐私政策',
						id: 3,
					},
					{
						name: '注册协议',
						id: 3,
					},
					{
						name: '免责声明',
						id: 3,
					},
				],
				scrollTop: 0,
				theLowestScore: [{
						url: 'https://www.12377.cn',
						img: 'http://nzzbw.com/up/image/ico/bot_1.gif',
					},
					{
						url: 'http://cyberpolice.mps.gov.cn/wfjb',
						img: 'http://nzzbw.com/up/image/ico/bot_2.gif',
					},
					{
						img: 'http://nzzbw.com/up/image/ico/bot_3.png',
					},
				],
				typedisplay: 0,
				byRegion_id: 0,
				searchContent: '',
				loginornot: localStorage.getItem('login_status'),
				friendshipPictures: [{
						website: 'http://www.hongyedianqi.com',
						picture: 'http://nzzbw.com/up/image/gg/01.png',
					},
					{
						website: 'http://yifan001.goepe.com',
						picture: 'http://nzzbw.com/up/image/gg/2.png',
					},
					{
						website: 'http://www.wmh999.cn',
						picture: 'http://nzzbw.com/up/image/gg/03.png',
					},
					{
						website: 'http://enlonhubery.cn.tonbao.com',
						picture: 'http://nzzbw.com/up/image/gg/04.png',
					},
					{
						website: 'http://www.zzdq.cn',
						picture: 'http://nzzbw.com/up/image/gg/05.png',
					},
					{
						website: 'http://www.chko.cn',
						picture: 'http://nzzbw.com/up/image/gg/06.png',
					},
					{
						website: 'http://www.reneshine.com',
						picture: 'http://nzzbw.com/up/image/gg/07.png',

					},
				],
				hotSearch: [' 保护管 ', '电梯 ', '医疗卫生 ', '计算器 ', '高压变频器 '],
				allRegions: [{
						id: 100000,
						regionName: '全国'
					},
					{
						id: 110000,
						regionName: '北京'
					},
					{
						id: 130000,
						regionName: '河北'
					},
					{
						id: 150000,
						regionName: '内蒙古'
					},
					{
						id: 140000,
						regionName: '山西'
					},
					{
						id: 120000,
						regionName: '天津'
					},
					{
						id: 440000,
						regionName: '广东'
					},
					{
						id: 450000,
						regionName: '广西'
					},
					{
						id: 460000,
						regionName: '海南'
					},
					{
						id: 340000,
						regionName: '安徽'
					},
					{
						id: 350000,
						regionName: '福建'
					},
					{
						id: 320000,
						regionName: '江苏'
					},
					{
						id: 370000,
						regionName: '山东'
					},
					{
						id: 310000,
						regionName: '上海'
					},
					{
						id: 330000,
						regionName: '浙江'
					},
					{
						id: 410000,
						regionName: '河南'
					},
					{
						id: 420000,
						regionName: '湖北'
					},
					{
						id: 430000,
						regionName: '湖南'
					},
					{
						id: 360000,
						regionName: '江西'
					},
					{
						id: 620000,
						regionName: '甘肃'
					},
					{
						id: 640000,
						regionName: '宁夏'
					},
					{
						id: 630000,
						regionName: '青海'
					},
					{
						id: 610000,
						regionName: '陕西'
					},
					{
						id: 650000,
						regionName: '新疆'
					},
					{
						id: 520000,
						regionName: '贵州'
					},
					{
						id: 510000,
						regionName: '四川'
					},
					{
						id: 540000,
						regionName: '西藏'
					},
					{
						id: 530000,
						regionName: '云南'
					},
					{
						id: 500000,
						regionName: '重庆'
					},

					{
						id: 210000,
						regionName: '辽宁'
					},
					{
						id: 220000,
						regionName: '吉林'
					},
					{
						id: 230000,
						regionName: '黑龙江'
					},
					{
						id: 810000,
						regionName: '香港'
					},
					{
						id: 820000,
						regionName: '澳门'
					},
					{
						id: 710000,
						regionName: '台湾'
					},
				],
				biddingInformation: 223568195,
				cumulativePush: 12311231130,
				cumulativeUpdate: 1234123,
				updatedToday: 123452,
				projectList: [],
				bulletinList: [],
				byType: ['全部', '房屋建筑', '交通工程', '市政工程', '水利工程', '医疗卫生'],
				byRegion: [{
						name: '全国',
						id: 100000,
					},
					{
						name: '福建',
						id: 350000,
					},
					{
						name: '安徽',
						id: 340000,
					},
					{
						name: '山东',
						id: 370000,
					},
					{
						name: '江苏',
						id: 320000,
					},
					{
						name: '江西',
						id: 360000,
					},
					{
						name: '浙江',
						id: 330000,
					},
					{
						name: '辽宁',
						id: 210000,
					},
					{
						name: '吉林',
						id: 220000,
					},
				],
				biddingNavigation: [{
						id: 1,
						name: '能源电力',
					},
					{
						id: 2,
						name: '机械设备',
					},
					{
						id: 3,
						name: '医疗卫生',
					},
					{
						id: 4,
						name: '农林牧渔',
					},
					{
						id: 5,
						name: '水利水电',
					},
					{
						id: 6,
						name: 'PPP项目',
					},
					{
						id: 7,
						name: '能源化工',
					},
					{
						id: 8,
						name: '科教文卫',
					},
					{
						id: 9,
						name: '信息通信',
					},
					{
						id: 10,
						name: '工程建筑',
					},
					{
						id: 11,
						name: '房屋建筑',
					},

					{
						id: 13,
						name: '服务采购',
					},
				],
				service: ['标书制作', '企业授权', '知识产权', '认证检测', '保函办理', '应用开发'],
				Formofbid: ['标书规范', '招标文件', '标书下载', '工程标书', '服务标书', '采购标书'],
				popularKeywords: ['仪器仪表', '铁附件', '道路维修', '工程泡沫板', '钩机与板车租赁', '道路维修板车租赁', '道路维修', '道路维修板车租赁', '钩机与板车租赁',
					'医疗', '高压变频器'
				],
				newKeyWords: ['仪器仪表', '铁附件', '道路维修', '工程泡沫板', '钩机与板车租赁', '道路维修板车租赁', '道路维修', '道路维修板车租赁', '钩机与板车租赁',
					'医疗', '高压变频器'
				],
				mode: '20',
			}
		},
		mounted() {
			var that = this
			document.onkeydown = function() { //监听键盘事件
				let key = window.event.keyCode
				//事件中keycode=13为回车事件
				if (key == 13) {
					if (that.searchContent != '') {
						that.onSearch()
					}
				}
			}
			if (this.loginornot == '' || this.loginornot == undefined) {
				this.loginornot = 1
			}
			if (this.loginornot == 1) {
				this.notice = 1
				this.bulletinList = []
			}
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
			this.handleScroll()
			window.addEventListener('scroll', this.handleScroll)
			this.onByType()
			this.onLatestannouncement()
			this.getStatisticsData()
		},
		methods: {
			onKeyWord(item) {
				this.$router.push({
					name: 'DetailSearch',
					params: {
						item: item,
					}
				})
			},
			handleScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			},
			onStow() {
				this.popularKeywords.splice(0, 11)
			},
			onLatestannouncement() {
				this.notice = 1
				if (localStorage.getItem('login_status') == 2) {
					postAllSearchData({
						"bid_type": [133, 134,],
						"content": '',
						"date": '',
						"date_type": 'week',
						"mode": this.mode,
						"page": 1,
						"region": [100000],
						"source": [],
					}).then((res) => {
						if (res.data.code == 200) {
							this.bulletinList = res.data.data.list
						}
						this.notice = 0
					}).catch(() => {
						this.notice = 0
					})
				} else {
					this.notice = 0
				}
			},
			onByType(index, item) {
				if (index != undefined) {
					this.typedisplay = index
				}
				if (item != undefined && item != '全部') {
					this.homeKeyWord = item
				} else {
					this.homeKeyWord = ''
				}
				if (this.loginornot == 2) {
					this.loading = 1
				} else {
					this.loading = 0
				}
				if (localStorage.getItem('login_status') == 2) {
					postAllSearchData({
						"bid_type": [133],
						"content": this.homeKeyWord,
						"date": '',
						"date_type": 'week',
						"mode": this.mode,
						"page": 1,
						"region": this.homeRegion,
						"source": [],
					}).then((res) => {
						if (res.data.code == 200) {
							this.projectList = res.data.data.list
							this.projectList.forEach((items) => {
								this.allRegions.forEach((item) => {
									if (items.region == item.id) {
										items.region = item.regionName
									}
								})
							})
							this.fullscreenLoading = false
							this.loading = 0
						}
					}).catch(() => {
						this.loading = 0
					})
				}
				this.fullscreenLoading = false
			},
			onByRegion(index, item) {
				this.homeRegion = []
				this.byRegion_id = index
				if (item.id == '' || item.id == undefined) {
					this.homeRegion.push(100000)
				} else {
					this.homeRegion.push(item.id)
				}
				if (this.loginornot == 2) {
					this.loading = 1
				} else {
					this.loading = 0
				}
				if (localStorage.getItem('login_status') == 2) {
					postAllSearchData({
						"bid_type": [133],
						"content": this.homeKeyWord,
						"date": '',
						"date_type": 'week',
						"mode": this.mode,
						"page": 1,
						"region": this.homeRegion,
						"source": [],
					}).then((res) => {
						if (res.data.code == 200) {
							this.projectList = res.data.data.list
							this.projectList.forEach((items) => {
								this.allRegions.forEach((item) => {
									if (items.region == item.id) {
										items.region = item.regionName
									}
								})
							})
						}
						this.loading = 0
					}).catch(() => {
						this.loading = 0
					})
				}
			},
			onSeeMore() {
				this.$router.push({
					name: 'DetailSearch',
				})
			},
			onProject() {
				this.$router.push({
					name: 'DetailSearch',
				})
			},
			onIncrease() {
				if (this.popularKeywords.length == 11) {
					this.newKeyWords.forEach((item) => {
						this.popularKeywords.push(item)
					})
				}
			},
			onSubscribe() {
				this.$router.push({
					name: 'MySubscriptions',
				})
			},
			onList(item) {
				const queryParams = {
					id: item.id,
					date: item.date
				}
				const routeURL = this.$router.resolve({
					name: 'DetailsPage',
					query: queryParams
				}).href

				window.open(routeURL, '_blank')
			},
			onSearch() {
				this.$router.push({
					name: 'DetailSearch',
					params: {
						item: this.searchContent,
						mode: this.mode,
					}
				})
			},
			onSignin() {
				if (localStorage.getItem('login_status') != 2) {
					this.$router.push({
						name: 'SignIn',
					})
				}
			},
			onBiddingNavigation(item) {
				this.$router.push({
					name: 'DetailSearch',
					params: {
						Navigation_id: item.id
					}
				})
			},
			onService(index) {
				this.$router.push({
					name: 'EnterpriseServices',
					params: {
						id: index
					}
				})
			},
			onFormofbid(index) {
				this.$router.push({
					name: 'FormOfBid',
					params: {
						document_id: index,
					}
				})
			},
			onBackToTop() {
				var timer = setInterval(function() {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop
					let ispeed = Math.floor(-osTop / 5)
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
					this.isTop = true
					if (osTop === 0) {
						clearInterval(timer)
					}
				}, 30)
			},
			getStatisticsData() {
				if (localStorage.getItem('login_status') == 2) {
					getStatistics().then((res) => {
						if (res.data.code == 200) {
							console.log(res.data.data)
							this.biddingInformation = res.data.data.value / 10000 * 60000 * 30 / 3
							this.cumulativePush = res.data.data.value / 10000 * 60000 * 30
							this.cumulativeUpdate = res.data.data.value
							this.updatedToday = res.data.data.today + 10000
						}
					}).catch(() => {})
				}			
			},
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll)
		},
	}
</script>

<style>
	body {
		margin: 0;
		padding: 0;
	}

	.home-body .login-page {
		z-index: 999;
	}

	.home-body .login-status {
		width: 100%;
		height: 100vh;
		background: rgba(0, 0, 0, .7);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 991;
		user-select: none;
	}

	.home-body .top_nav {
		background-color: #fff !important;
		color: #000 !important;
	}

	.home-body .top_nav .top-nav-hand:hover {
		color: var(--theme-color) !important;
	}

	.home-body {
		background-color: #f8f8f8;
	}

	.home-body .user-left {
		box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, .3);
		border-radius: 5px;
		padding: 0px 5px;
		text-align: center;
		width: 50px;
		font-size: 12px;
		position: fixed;
		right: 0;
		top: 180px;
		background-color: #fff;
	}

	.home-body .user-left .official-account {
		cursor: pointer;
		padding: 10px 0;
		border-bottom: 1px solid #eeeeee;
	}

	.concern-no {
		text-align: center !important;
	}

	.concernqrcode {
		text-align: center;
		width: 200px;
	}

	.home-body .user-left .back-to-top {
		padding: 10px 0;
		cursor: pointer;
	}

	.home-body .user-left .back-to-top img {
		width: 25px;
	}

	.home-body .user-left img {
		width: 30px;
	}

	.home-body .home .home-title {
		font-size: 30px;
		color: #fff;
		text-align: center;
		padding-top: 100px;
	}

	.home-body .home .search {
		width: 50%;
		margin: 0 25%;
		margin-top: 60px;
	}

	.home-body .active {
		color: #fff;
		background-color: var(--theme-color) !important;
	}

	.home-body .home .hotSearch {
		width: 50%;
		margin: 0 25%;
		margin-top: 20px;
		color: #fff;
		text-align: center;
	}

	.home-body .home .hotSearch .search-term {
		padding: 0 5px;
	}

	.home-body .home .hotSearch .search-term:hover {
		cursor: pointer;
	}

	.home-body .home .search .input-with-select .el-input-group__prepend {
		background-color: #fff;
	}

	.home-body .el-input__inner {
		height: 46px !important;
		line-height: 46px !important;
		border: 1px solid #DCDFE6 !important;
	}

	.home-body .el-input-group__append {
		border: none !important;
	}

	.home-body .el-button {
		width: 80px;
	}

	.home-body .search .searchButton {
		height: 46px !important;
		background-color: #fdbb31 !important;
		color: #fff !important;
		border: 1px solid #fdbb31 !important;
	}

	.home-body .home {
		width: 100%;
		height: 350px;
		background: var(--theme-color);
		margin: 0 auto;
	}

	.home-body .platformData {
		margin: 0 10%;
		text-align: center;
		margin-top: -50px;
		background-color: #fff;
		border-radius: 10px;
		font-size: 14px;
	}

	.home-body .platformData .hand {
		cursor: pointer;
		width: 50%;
		margin: 0 25%;
	}

	.home-body .platformData .platformData-top {
		margin-top: 10px;
		line-height: 40px;
	}

	.home-body .platformData .platformData-date {
		line-height: 40px;
		margin-bottom: 10px;
	}

	.home-body .bidding-screening {
		margin: 0 10%;
		margin-top: 20px;
	}

	.home-body .bidding-screening .bidding-screening-left {
		width: 78%;
		font-size: 14px;
	}

	.home-body .bidding-screening .bidding-screening-left .lump {
		background-color: #fff;
	}

	.home-body .bidding-screening .bidding-screening-left .keyword-hot {
		background-color: #fff;
		padding-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.home-body .bidding-screening .bidding-screening-left .keyword-hot .stow {
		position: absolute;
		bottom: 10px;
		right: 24%;
		background: var(--theme-color);
		padding: 5px 10px;
		border-radius: 5px;
		color: #fff;
		cursor: pointer;
	}

	.home-body .bidding-screening .bidding-screening-left .keyword-hot .keyword {
		max-width: 68px;
		min-width: 28px;
		margin: 20px 20px 0 20px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.home-body .bidding-screening .bidding-screening-left .keyword-hot .keyword:hover {
		cursor: pointer;
		color: var(--theme-color);
	}

	.home-body .bidding-screening .bidding-screening-left .list-top {
		background-color: #fff;
		border-radius: 0px 0px 5px 5px;
		padding-bottom: 20px;
	}

	.home-body .bidding-screening .bidding-screening-left .list-top .not-logged-in {
		text-align: center;
	}

	.home-body .bidding-screening .bidding-screening-left .list-top .not-logged-in img {
		width: 250px;
	}

	.home-body .bidding-screening .bidding-screening-left .list-top .please-login {
		text-align: center;
		font-size: 16px;
		color: var(--theme-color);
	}

	.home-body .bidding-screening .bidding-screening-left .classification {
		background-color: #fff;
		line-height: 28px;
		padding: 20px;
		border-radius: 5px;
	}

	.home-body .bidding-screening .bidding-screening-left .classification .seemore {
		cursor: pointer;
	}

	.home-body .bidding-screening .bidding-screening-left .classification .classification-bytype {
		padding: 0 10px;
		border-radius: 4px;
		cursor: pointer;
	}

	.home-body .bidding-screening .bidding-screening-left .classification .classification-bytype:hover {
		background-color: var(--theme-color);
		color: #fff;
	}
	.home-body .bidding-screening .bidding-screening-left .classification .top-margin {
		margin-top: 20px;
	}

	.home-body .bidding-screening .bidding-screening-left .latest-content {
		padding: 0 20px;
		background-color: var(--theme-color);
		color: #fff;
		line-height: 40px;
		border-radius: 5px;
		margin-top: 20px;
	}

	.home-body .bidding-screening .bidding-screening-left .latest-content .seemore {
		cursor: pointer;
	}

	.home-body .bidding-screening .bidding-screening-left .latest-content .el-icon-d-arrow-right {
		line-height: 40px;
		margin-left: 5px;
		cursor: pointer;
	}

	.home-body .bidding-screening .bidding-screening-left .list {
		padding: 0 20px 0px 20px;
		cursor: pointer;
		margin-top: 20px;
	}

	.home-body .bidding-screening .bidding-screening-left .list:hover {
		color: var(--theme-color);
	}

	.home-body .bidding-screening .bidding-screening-left .list .list-title {
		width: 70%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 24px;
	}

	.home-body .bidding-screening .bidding-screening-left .list .list-type {
		background-color: var(--theme-color);
		font-size: 12px;
		padding: 4px 10px;
		color: #fff;
		border-radius: 5px;
	}

	.home-body .bidding-screening .bidding-screening-left .list .list-region {
		line-height: 24px;
	}

	.home-body .bidding-screening .bidding-screening-left .list .list-time {
		line-height: 24px;
	}

	.home-body .bidding-screening .bidding-screening-right {
		margin-left: 2%;
		width: 20%;
		text-align: center;
		border-radius: 5px;
	}

	.home-body .bidding-screening .bidding-screening-right .upper {
		margin-top: 20px;
	}

	.home-body .bidding-screening .bidding-screening-right .navigation {
		margin-top: 20px;
		background-color: #fff;
		font-size: 14px;
	}

	.home-body .bidding-screening .bidding-screening-right .navigation .popular-navigation {
		padding-top: 20px;
		font-weight: bold;
	}

	.home-body .bidding-screening .bidding-screening-right .navigation .popular-navigation-list {
		padding: 0 14px;
		padding-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.home-body .bidding-screening .bidding-screening-right .navigation .popular-navigation-list .nav-list {
		width: 30%;
		margin-top: 20px;
		text-align: center;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.home-body .nav-list a {
		text-decoration: none;
		color: #000;
	}

	.home-body .nav-list a:hover {
		color: var(--theme-color);
	}

	.home-body .nav-list:hover {
		color: var(--theme-color);
	}

	.home-body .bidding-screening .bidding-screening-right .subscribe {
		padding: 0 20px 1px 20px;
		background-color: #fff;
		border-radius: 5px;
		cursor: pointer;
	}

	.home-body .bidding-screening .bidding-screening-right .subscribe .bidding {
		padding-top: 20px;
		font-size: 14px;
		font-weight: bold;
	}

	.home-body .bidding-screening .bidding-screening-right .subscribe .bidding-tips {
		font-size: 12px;
		padding: 20px 0px;
	}

	.home-body .bidding-screening .bidding-screening-right .tips-top {
		padding: 20px;
		font-size: 14px;
		font-weight: bold;
	}

	.home-body .bidding-screening .bidding-screening-right .tips-in {
		font-size: 12px;
		font-weight: bold;
	}

	.home-body .bidding-screening .bidding-screening-right .mobilePhone img {
		margin-top: 20px;
		width: 50px;
	}

	.home-body .bidding-screening .bidding-screening-right .Tips-bottom {
		margin-top: 10px;
		font-size: 12px;
	}

	.home-body .bidding-screening .bidding-screening-right .sign-in {
		cursor: pointer;
		margin: 0 20%;
		margin-top: 20px;
		background-color: var(--theme-color);
		color: #fff;
		width: 60%;
		margin-bottom: 20px;
		font-size: 12px;
		font-weight: bold;
		line-height: 26px;
		border-radius: 5px;
	}

	.el-select-dropdown__item:not(.selected) {
		color: initial; /* 恢复默认字体颜色 */
	}
	.el-select-dropdown__item.selected {
		color: var(--theme-color) !important; /* 设置选中项的颜色 */
	}
	.el-select {
		width: 80px;
		text-align: center;
	}
</style>

<!-- 顶部导航 -->
<template>
	<div class="top-top">
		<el-row type="flex" class="top-nav">
			<el-col class="companylogo" @click="onHome">
				<div class="">
					<img src="@/assets/biaobaiotong.png" v-if="state == 1">
					<img src="@/assets/iocn.jpg" v-if="state == 2">
				</div>
			</el-col>
			<el-col>
				<div class="top-nav-company" @click="onHome" v-if="state == 1">
					标标通
				</div>
				<div class="top-nav-company" @click="onHome" v-if="state == 2">
					能者招标
				</div>
			</el-col>
			<el-col></el-col>
			<el-col class="top-nav-home">
				<div class="top-nav-hand" @click="onHome">
					首页
				</div>
			</el-col>
			<!-- <el-col>
				<div class="top-nav-hand" @click="onFormOfBid">
					标书范本
				</div>
			</el-col> -->
			<!-- <el-col>
				<div class="top-nav-hand" @click="onEnterpriseServices" v-if="state == 1">
					企业服务
				</div>
				<div class="top-nav-hand" @click="onEnterpriseServices" v-if="state == 2">
					能者企服
				</div>
			</el-col> -->
			<el-col>
				<div class="top-nav-hand" @click="onAboutUs">
					关于我们
				</div>
			</el-col>
			<el-col>
				<div class="top-nav-hand" @click="onMember">
					VIP会员
				</div>
			</el-col>
			<el-col>
				<div class="top-nav-hand" @click="registrationAndLogin" v-if="loginStatus == 1">
					登录/注册
				</div>
				<div class="top-nav-hand" @click="onMemberCenter" v-else>
					会员中心
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import DifferentEnvironment from '@/DifferentEnvironment.vue'
	export default {
		name: 'TopNav',
		components: {

		},
		data() {
			return {
				state:DifferentEnvironment.state,
				loginStatus: localStorage.getItem('login_status'),
			}
		},
		mounted() {
			if (this.loginStatus == undefined || this.loginStatus == '') {
				this.loginStatus = 1
			}
		},
		methods: {
			onHome() {
				if (location.pathname != '/') {
					this.$router.push({
						name: 'HomeView',
					})
				}
			},
			onMemberCenter() {
				if (location.pathname != '/membercenter') {
					this.$router.push({
						name: 'MemberCenter',
					})
				}
			},
			registrationAndLogin() {
				if (this.loginStatus === 1) {
					if (location.pathname != '/signin') {
						this.$router.push({
							name: 'SignIn',
						})
					}
				} else {
					this.$router.push({
						name: 'MemberCenter',
					})
				}
			},
			onMember() {
				if (location.pathname != '/memberactivation') {
					this.$router.push({
						name: 'MemberActivation',
					})
				}
			},
			onAboutUs() {
				if (location.pathname != '/aboutus') {
					this.$router.push({
						name: 'AboutUs',
					})
				}
			},
			onEnterpriseServices() {
				if (location.pathname != '/enterpriseservices') {
					this.$router.push({
						name: 'EnterpriseServices',
					})
				}
			},
			onFormOfBid() {
				if (location.pathname != '/formofbid') {
					this.$router.push({
						name: 'FormOfBid',
					})
				}
			}
		}
	}
</script>

<style>
	.top-top {
		z-index: 999;
		height: 50px;
		color: #fff;
		background: rgba(0, 0, 0, 0.1);
		padding: 0 10%;
		width: 80%;
		position: fixed;
		top: 0;
		left: 0;
		user-select: none;
	}

	.top-top .top-nav {
		height: 50px;
		line-height: 50px;
		font-size: 14px;
		text-align: center;
	}

	.top-top .top-nav .top-nav-hand {
		width: 75%;
		margin: 0 25%;
		cursor: pointer;
	}

	.top-top .top-nav .companylogo {
		width: 40px;
		height: 40px;
		margin: 5px 0;
		cursor: pointer;
	}

	.top-top .top-nav .companylogo img {
		width: 40px;
		height: 40px;
	}

	.top-top .top-nav .top-nav-company {
		width: 100px;
		font-weight: bold;
		font-size: 18px;
		cursor: pointer;
	}

	.top-top .top-nav .top-nav-company .top-nav-home {
		margin-top: 200px;
	}
</style>

<template>
	<div class="">
		<div class="number-grow-warp">
			<span ref="numberGrow" :data-time="time" class="number-grow">0</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'DigitalAnimation',
		components: {

		},
		props: {
			time: {
				type: Number,
				default: 1
			},
			value: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {

			}
		},
		mounted() {
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
			this.numberGrow(this.$refs.numberGrow)
		},
		methods: {
			numberGrow(ele) {
				let step = parseInt((this.value) / (this.time * 100))
				let current = 0
				let start = 0
				let t = setInterval(() => {
					start += step + 10
					if (start >= this.value) {
						clearInterval(t)
						start = this.value
						t = null
					}
					if (current === start) {
						return
					}
					current = start
					ele.innerHTML = current.toString().replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1,')
				}, 10)
			}
		}
	}
</script>

<style>


</style>
